import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faHome, faStar } from '@fortawesome/free-solid-svg-icons'; // Importe o ícone de Home e o ícone de estrela para avaliação
import { useNavigate } from 'react-router-dom';
import { useCart } from '../Cart/CartContext';
import { useSpring, animated } from 'react-spring';
import { ReactComponent as Paw } from '../../assets/paw.svg';
import { ReactComponent as Mail } from '../../assets/email-svgrepo-com.svg';
import { ReactComponent as Book } from '../../assets/book-svgrepo-com.svg';
import { TopbarWrapper } from './styles';
import { Cart } from '../Cart/Cart';

export const TopbarNav: React.FC = () => {
    const { cart } = useCart();
    const navigate = useNavigate();
    const menuItems = [
        { path: '/', icon: <FontAwesomeIcon icon={faHome} color='#000' />, label: 'Home' },
        { path: '/cadastro', icon: <Paw />, label: 'Cadastro' },
        { path: '/contato', icon: <Mail />, label: 'Contato' },
        { path: '/avaliacao', icon: <FontAwesomeIcon icon={faStar} color='#000' />, label: 'Avaliação' }, // Adicione a opção de avaliação
        // { path: '/ebooks', icon: <Book />, label: 'E-books' }, // Opção de e-books desabilitada
    ];

    return (
        <TopbarWrapper>
            {menuItems.map((item, index) => (
                <a key={index} onClick={() => navigate(item.path)}>
                    <div className="menuItems">
                        {item.icon}
                        <strong>{item.label}</strong>
                    </div>
                </a>
            ))}
        </TopbarWrapper>
    );
};
