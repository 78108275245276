import styled from 'styled-components';

export const ContactPage = styled.section`
    background: #292929;
    display: flex;
    justify-content: center;
    width: 100%;
    color: #fff;

    h1 {
        text-align: center;
        font-size: 2.5rem;
    }
`;

export const CenterBottomContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 324px;
    width: 70%;
    padding-bottom: 4rem;

    @media (max-width: 780px) {
        padding: 1.18% 5% 0 5%;
        margin-bottom: 2rem;

        h1 {
            font-size: 2rem;
        }
    }

    .topText {
        padding: 4% 0 2% 0;
        width: 100%;

        h2 {
            font-size: 1.5rem;
            color: white;
            text-align: center;
        }

        @media (max-width: 780px) {
            padding: 3rem 0 2rem 0;
        }
    }
`;

export const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
`;
