import React, { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import GlobalStyle from './styles/global';
import Main from './pages/Main';
import CustomerRegistration from './pages/Customer';
import Contact from './pages/Contact';
import { Content } from './pages/Main/styles';
import { TopbarNav } from './components/TopbarNav/TopbarNav';
import logoImg from './assets/Logo pet.svg';
import { CartProvider } from './components/Cart/CartContext';
import AssesmentPage from './pages/Assesment';
import { TopBar } from './components/TopbarNav/styles';
import EventRegistration from './pages/EventRegistration';


export const App: React.FC = () => {
  return (
    <CartProvider>
      <Content>
        <BrowserRouter>
          <TopBarComponent />

          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/cadastro" element={<CustomerRegistration />} />
            <Route path="/cadastroEvento" element={<EventRegistration />} />
            <Route path="/contato" element={<Contact />} />
            <Route path="/avaliacao" element={<AssesmentPage />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>

        </BrowserRouter>
      </Content>
      <GlobalStyle />
    </CartProvider>
  );
};
const TopBarComponent: React.FC = () => {
  return (
    <TopBar>
      <div className="logo">
        <a href='https://www.instagram.com/aee.pet/' target="_blank">
          <img src={logoImg} alt="Instagram" />
        </a>
      </div>
      <TopbarNav />
    </TopBar>
  );
};
