import styled from 'styled-components';
export const CustomerRegister = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; // Define a altura da página inteira para centralizar verticalmente
    padding: 2rem; // Adiciona algum espaço ao redor do formulário
    background-color: #292929; // Cor de fundo opcional

    iframe {
        border: none; // Remove a borda do iframe
    }
`;


