import React, { createContext, useContext, useReducer, Dispatch } from 'react';
import { IProduct } from '../../@types/DataTypes';

// Defina o tipo para os itens do carrinho
export type CartItem = IProduct;

// Defina o tipo para o estado do carrinho
interface CartState {
  cart: CartItem[];
  cartItemCount: number;
}

// Defina as ações possíveis para o carrinho
type CartAction =
  | { type: 'ADD_TO_CART'; payload: CartItem }
  | { type: 'REMOVE_FROM_CART'; payload: number }
  | { type: 'CLEAR_CART' };

interface CartContextType {
  cart: IProduct[];
  addToCart: (product: IProduct) => void;
  removeFromCart: (productId: number) => void;
  clearCart: () => void;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

const cartReducer = (state: CartState, action: CartAction): CartState => {
  switch (action.type) {
    case 'ADD_TO_CART':
      return {
        ...state,
        cart: [...state.cart, action.payload],
        cartItemCount: state.cartItemCount + 1,
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload),
        cartItemCount: state.cartItemCount - 1,
      };
    case 'CLEAR_CART':
      return {
        ...state,
        cart: [],
        cartItemCount: 0,
      };
    default:
      return state;
  }
};

export const CartProvider: React.FC = ({ children }) => {
  const initialState: CartState = {
    cart: [],
    cartItemCount: 0,
  };
  const [state, dispatch] = useReducer(cartReducer, initialState);

  const addToCart = (product: IProduct) => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const removeFromCart = (productId: number) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: productId });
  };

  const clearCart = () => {
    dispatch({ type: 'CLEAR_CART' });
  };

  return (
    <CartContext.Provider value={{ cart: state.cart, addToCart, removeFromCart, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};
export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
