import { EmailData } from "../@types/DataTypes";
import emailjs from '@emailjs/browser';

export const sendEmail = async (emailData: EmailData) => {
    const { to, subject, text, fullName, cli_message,phoneNumber } = emailData;
    try {

        emailjs.send(
            "service_11kqwxu",
            "template_uwipp2k", {
            from_name: subject,
            to_name: to,
            message: text,
            cli_message,
            fullName,
            phoneNumber
        },
            "OS-XGGXyIQXzm0IuW",
        )
            .then((response) => {
                console.log(response)
            })

    } catch (error) {
        console.error(error);
    }
};