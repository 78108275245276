import React from 'react';
import { Assesment } from './styles';

const AssesmentPage = () => {
    return (
        <Assesment>
            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfR1tddhEeq73Bq7tlEsyhsYVgOqWf5P8sxUzgACy9jvcrKow/viewform?embedded=true"
                width="640"
                height="3987"
            >
                Carregando…
            </iframe>
        </Assesment>
    );
};

export default AssesmentPage;
