import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

const floatIn = keyframes`
    from {
        opacity: 0%;
    }
    to {
        opacity: 100%;
    }
`;

interface ButtonProps {
    floating?: boolean;
    buttonText?: string;
}

const StyledButton = styled.button<ButtonProps>`
    background-color: ${({ floating }) => (floating ? '#25D366' : 'transparent')};
    border: none;
    color: ${({ floating }) => (floating ? '#fff' : '#25D366')};
    padding: ${({ floating }) => (floating ? '10px 15px' : '10px 15px')};
    text-align: center;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ floating }) => (floating ? '1.2rem' : '1rem')};
    min-height: ${({ floating }) => (floating ? '50px' : '42px')};
    min-width: ${({ floating }) => (floating ? '60px' : '200px')};
    max-width: ${({ floating }) => (floating ? '60px' : '260px')};
    border-radius: ${({ floating }) => (floating ? '25px 30px 5px 25px' : '10px')};
    position: ${({ floating }) => (floating ? 'fixed' : 'relative')};
    bottom: ${({ floating }) => (floating ? '30px' : 'auto')};
    right: ${({ floating }) => (floating ? '30px' : 'auto')};
    box-shadow: ${({ floating }) =>
        floating ? '1px 2px 5px 2px rgba(30,30,30,0.3)' : '0 4px 8px rgba(0, 0, 0, 0.1)'};
    overflow: ${({ floating }) => (floating ? 'visible' : 'hidden')};
    white-space: nowrap;
    transition: ${({ floating }) => (floating ? 'all 0.3s ease-out' : 'none')};
    animation: ${({ floating }) => (floating ? css`${floatIn} 0.5s ease-in-out` : 'none')};

    &:active {
        background-color: ${({ floating }) => (floating ? '#128C7E' : '#128C7E')};
        transform:  ${({ floating }) => (floating ? 'none' : 'translateY(4px)')};
    }
    &:hover {
    background-color: ${({ floating }) => (floating ? '#128C7E' : '#128C7E')};
    color: ${({ floating }) => (floating ? '#fff' : '#fff')};
    animation: ${({ floating }) => (floating ? css`${floatIn} 0.5s ease-in-out` : 'none')};
    max-width: ${({ floating }) => (floating ? '250px' : '260px')};
    transform:  ${({ floating }) => (floating ? 'none' : 'translateY(2px)')};
    box-shadow:  ${({ floating }) => (floating ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)')};
}
@media screen and(max-width: 768px) {
    max-width: ${({ floating }) => (floating ? '60px' : '200px')};
    max-width: ${({ floating }) => (floating ? '200px' : '200px')};
}
@media screen and(max-width: 545px) {
    max-width: ${({ floating }) => (floating ? '60px' : '200px')};
    max-width: ${({ floating }) => (floating ? '60px' : '200px')};
}
`;

const FloatingButtonText = styled.span<{ visible?: boolean }>`
display: ${({ visible }) => (visible ? 'inline' : 'none')};
opacity: ${({ visible }) => (visible ? '1' : '0')};
transition: opacity 0.3s ease;
font-size: 1rem;
`;

const WhatsAppIcon = styled(FontAwesomeIcon) <{ floating?: boolean | string }>`
    font-size: ${({ floating }) => (floating === 'true' || floating === true ? '3rem' : '1.2rem')};
    padding-right: ${({ floating }) => (floating === 'true' || floating === true ? '5px' : '8px')};
    padding-left: ${({ floating }) => (floating === 'true' || floating === true ? '5px' : '8px')};
    opacity: ${({ floating }) => (floating === 'true' || floating === true ? '1' : '0')};
    transition: opacity 0.3s ease;
`;


export const WhatsButton: React.FC<ButtonProps> = ({ floating }) => {
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();


    const handleHover = (hovering: boolean) => {
        setIsHovered(hovering);
    };

    const handleClick = () => {
        const message = encodeURIComponent("Olá, estava no site e gostaria de mais informações sobre adestramento");
        window.open(`https://api.whatsapp.com/send?phone=5511971919069&text=${message}`);
        { setTimeout(() => navigate('./cadastro'), 4500) }

    };

    return (
        <>
            <StyledButton
                floating={floating}
                onClick={handleClick}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
            >
                {!isHovered && <WhatsAppIcon floating={floating ? 'true' : 'false'} icon={faWhatsapp as IconDefinition} />}
                <FloatingButtonText visible={!floating || (isHovered && floating)}>
                    {floating ? 'Entre em contato pelo WhatsApp' : 'Falar com especialista'}
                </FloatingButtonText>
            </StyledButton>
        </>
    );
};
