import { CustomerRegister } from './styles';

const CustomerRegistration = () => {
    return (
        <CustomerRegister>

            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSeGTjhONCKBIK4Ov6Hi2z9Zvll6XhBv1jlgDYDCUJIgYJPuqw/viewform?embedded=true"
                frameBorder="0"
                width="740"
                height="3500"
                style={{ overflowY: 'hidden' }} // Adicione este estilo
            >
                Carregando…
            </iframe>
        </CustomerRegister>
    )
}
export default CustomerRegistration;
