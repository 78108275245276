import styled from 'styled-components';

export const Content = styled.main`
   background: #F5F5F5;
`;

export const TopBanner = styled.section`
    width: 100%;
    background: #F5F5F5;
    padding: 4rem 0;

    @media (max-width: 768px) {
        padding: 2rem 0;
    }
`;

export const CenterBannerContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const ResponsiveContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        margin-bottom: 2rem;
        flex-direction: column;
        width: 100%; /* Faz o container ocupar 100% da largura */
    }
`;

export const LeftBoxTalkWithSpecialists = styled.div`
    display: flex;
    text-align: left;
    margin-right: 2rem;
    flex-direction: column;
    @media (max-width: 768px) {
        margin-right: 0;
        width: 100%; /* Faz o container ocupar 100% da largura */
    }
`;

export const LeftBoxButtonArea = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    @media (max-width: 768px) {
        align-items: center; /* Centraliza os botões em telas menores */
        width: 100%; /* Faz o container ocupar 100% da largura */
    }

    a {
        text-decoration: none; /* Remove sublinhado dos links */
        width: 100%; /* Faz o link ocupar 100% da largura */
        display: flex; /* Garante que o link abrace o botão */
        justify-content: center; /* Centraliza o botão */
    }

    /* Coloração para garantir que os botões mantenham a cor */
    .defaultButton {
        background: #007fff; /* Cor de fundo */
        border-radius: 10px;
        color: #fff; /* Cor do texto */
        font-size: 1rem;
        min-height: 42px; /* Altura mínima */
        padding: 10px 20px; /* Espaçamento interno */
        width: 100%;
        max-width: 260px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
    }

    .defaultButton:hover {
        background-color: #004fff;
        color: #fff;
    }

    .defaultButton:active {
        transform: translateY(2px); /* Suave movimentação ao clicar */
    }

    @media (max-width: 768px) {
        .defaultButton {
            font-size: 0.9rem;
            padding: 12px 16px;
        }
    }
`;

/* Seção de convite para contato */
export const CallToActionContainer = styled.section`
    background-color: #fff;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

export const CallToActionText = styled.div`
    max-width: 600px;
    padding: 1rem;

    h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        color: #007fff;
    }

    p {
        font-size: 1.2rem;
        color: #333;
        margin-bottom: 1.5rem;
    }

    .defaultButton {
        background: #007fff;
        border-radius: 10px;
        color: #fff;
        font-size: 1rem;
        min-height: 42px;
        width: 100%;
        max-width: 260px;
        padding: 10px 20px;
        transition: all 0.2s ease-in-out;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border: none;
        cursor: pointer;
    }

    .defaultButton:hover {
        background-color: #004fff;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1rem;
        }

        .defaultButton {
            font-size: 0.9rem;
            padding: 12px 16px;
        }
    }
`;
