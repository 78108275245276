import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-4px);
  }
`;

export const TopbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(90deg, rgba(34, 187, 255, 1) 10%, rgba(0, 127, 255, 1) 35%, rgba(34, 187, 255, 1) 100%);
  color: #fff;
  padding-right: 2rem;

  a {
    text-decoration: none;
    color: #fff;
  }

  .menuItems {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-right: 1rem;

    &:hover {
      background: #007fff;
      transition: background 0.3s;
    }
    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
    strong {
      font-size: 1rem;
    }
  }

  .menuItemsCart {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
 
    overflow: hidden;

    .cartCounter {
      position: absolute;
      top: 4px;
      right: 4px;
      background-color: #ff4f4f;
      color: #fff;
      border-radius: 50%;
      padding: 0.2rem 0.5rem;
      font-size: 0.8rem;
      font-weight: bold;
      animation: ${bounce} 0.6s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 1rem;
    align-items: flex-start;
    .menuItems,
    .menuItemsCart {
      padding: 0.5rem 1rem;

      
    }
  }
`;
export const TopBar = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
    backdrop-filter: blur(10px);
    
    .links{
        text-align: center;
        padding: 0 1rem 0 0;

        ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #333333;
        }

        li {
        float: right;
        }

        li a {
        display: flex;
        color: #fff;
        justify-content: center;
        padding: .5rem 1rem;
        text-decoration: none;
        }

        li a:hover {
        background-color: #111111;
        }

        height:50%;
        background-color: #888;
    }
    
    .logo{
        overflow: hidden;
        display: flex;
        justify-content: center;
        width: 170px;
        margin: auto;
        margin-bottom: 5px;
        height:40px;
        img { width:100px;}
        :hover{
            opacity: 0.6;
        }
    }
   `
