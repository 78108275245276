import styled from 'styled-components';
export const StyledForm = styled.form` 
        .form-group{  
            align-items:center;
            display:flex;
            flex-direction: column;
            justify-content:center;
            width:100%;
            .formItem {
            display: flex;
            flex-direction: column;
            width:100%;
            margin-bottom:1rem;

            .formLabel { display: flex;}
            }
        }    
        input{
          background: transparent;
          border:1px solid #fff;
          border-radius:6px;
          padding:1rem ;
          color: #fff;
        ::placeholder{
            color:#fff;
            }
        } 
        label{
            font-size:1rem;
            color:#fff;
            padding-bottom:1rem;
        }
        textarea{
            background-color: #f5f5f5;
            border: none;
            border-radius: 5px;
            box-shadow: none;
            color: #333;
            font-family: 'Roboto', sans-serif;
            font-size: 1rem;
            height: 7.5rem;
            line-height: 1.4;
            padding: 0.625rem;
            resize: none;

            &:focus {
                box-shadow: 0 0 0 2px #1e90ff;
                outline: none;
            }
        }
        span{
                display: inline-block;
                padding: 0.625rem 1.25rem;
                background-color: #292929;
                color: #fff;
                border-radius: 5px;
                transition: background-color 0.3s ease-in-out;
              
              &:hover {
                background-color: #ff0000;
              }
        }

        button{
            &.disabled { 
                background: gray;
                filter: brightness(0.5);
                :hover {
                    color:#fff;
                    background: gray;
                    opacity: 0.5;
                    // cursor: not-allowed;

                }
            }
                background:#007fff;
                border-radius:6px;
                color: #fff;
                font-size:1rem;
                height: 70px;
                min-height: 2.625rem;
                max-height: 4.8125rem;
                transition: all .2s ease-in-out;
                width: 17.125rem;
                     a {
                    color: inherit; /* blue colors for links too */
                    text-decoration: inherit; /* no underline */
                    }
                     :hover {
                        color:#004fff;
                        background-color:#fff;
                    }
            }
`
export const CharCounter = styled.span`
bottom: 10px;
right: 10px;
font-size: 0.75rem;
color: #666;
`;