import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* div{
    border: 1px solid #000;
  } */

  body {
    background: #fff;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
    min-width: 300px;
  }

  body, input, button {
    font-family: Ubuntu, sans-serif;
  }

  button {
    cursor: pointer;
    border: none;
    height: 4rem;
    width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  :root {
    --bg-color: #fff;
    --bt-color: #007fff;
    --dark-primary-color: #003EDF;
    --light-primary-color: #22BBFF;
    --primary-color: #007FFF;
  }
`;
