import styled from 'styled-components';

export const Assesment = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem; // Adiciona algum espaço ao redor do formulário
    background-color: #f5f5f5; // Cor de fundo opcional

    iframe {
        border: none; // Remove a borda do iframe
    }
`;
