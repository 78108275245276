import { InfinitySpin } from 'react-loader-spinner';
import { CenterBottomContentContainer, ContactPage, LoaderContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CharCounter, StyledForm } from '../../components/Form/styles';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import axios from 'axios';
import { sendEmail } from '../../services/SendEmail';
import { getToday } from '../../utils/date';
import { mtel } from '../../utils/masks';

type FormValues = {
    fullName: string;
    phoneNumber: string;
    email: string;
    cpf?: string;
    message: string; // Adicionado o campo "message"
};

const maxLengthMessage = 300;
// const config = (window as any).GlobalConfig;


const Contact = () => {
    const navigate = useNavigate();
    const [contactSuccess, setContactSuccess] = useState(false);
    const { control, handleSubmit, formState } = useForm<FormValues>();
    const [clientText, setClientText] = useState('');
    const [clientTextCounter, setClientTextCounter] = useState(0);


    const onChangeMessage = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setClientText(ev.target.value);
        setClientTextCounter(ev.target.value.length);
    };


    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            const response = await axios.post('/api/contact', data);
            console.log(response);
        } catch (error) {
            console.log(error);
        }
        try {
            sendEmail({
                to: data.email,
                subject: `Nova Consulta: ${data.fullName} | ${getToday()}`,
                fullName: data.fullName,
                phoneNumber: data.phoneNumber,
                cli_message: clientText,
                text: `Cliente: ${data.fullName}
          \n Telefone: ${data.phoneNumber} 
          \n Mensagem: ${clientText} 
          \n `,
            });
            setContactSuccess(true);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ContactPage >
            <CenterBottomContentContainer id="form">
                <div className="topText" >
                    {contactSuccess
                        ? <>
                            <p>
                                <h1>Perfeito! Dados enviados com sucesso!</h1>
                                <br></br>
                                <h2>Estou direcionando a página de cadastro, assim nos conhecemos melhor</h2>
                                <br></br>
                                {setTimeout(() => navigate('./cadastro'), 4500)}
                            </p>
                            <LoaderContainer>
                                <InfinitySpin color='white' />
                            </LoaderContainer>

                            {/* {isDogImageLoaded && <DogLoader dogImage={dogImage} />} */}

                        </>
                        : <h1>Entre em contato<br></br></h1>
                    }
                </div>
                {!contactSuccess &&
                    <StyledForm onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <div className="formItem">
                                <div className="formLabel">
                                    <label htmlFor="fullName">Nome Completo</label>
                                </div>
                                <Controller
                                    name="fullName"
                                    defaultValue=''
                                    control={control}
                                    render={({ field }) => (
                                        <input {...field} id="fullName" type="text" placeholder="Nome Completo" />
                                    )}
                                />
                                {formState.errors.fullName && <span>Campo obrigatório</span>}
                            </div>
                            <div className="formItem">
                                <div className="formLabel">
                                    <label htmlFor="phoneNumber">Telefone</label>
                                </div>
                                <Controller
                                    name="phoneNumber"
                                    defaultValue=''
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            placeholder="Digite o número do seu Celular"
                                            onChange={(e) => field.onChange(mtel(e.target.value))}
                                        />
                                    )}
                                />
                                {formState.errors.phoneNumber && <span>{formState.errors.phoneNumber.message}</span>}
                            </div>
                            <div className="formItem">
                                <div className="formLabel">
                                    <label htmlFor="email">E-mail</label>
                                </div>
                                <Controller
                                    name="email"
                                    defaultValue=''
                                    control={control}
                                    render={({ field }) => (
                                        <input type="email" {...field} />
                                    )}
                                />
                                {formState.errors.email && <span>{formState.errors.email.message}</span>}
                            </div>
                            <div className="formItem">
                                <div className="formLabel">
                                    <label htmlFor="message">Mensagem</label>
                                </div>
                                <Controller
                                    name="message"
                                    defaultValue=''
                                    control={control}
                                    rules={{ maxLength: maxLengthMessage }}
                                    render={({ field }) => (
                                        <>
                                            <textarea
                                                {...field}
                                                id="message"
                                                maxLength={maxLengthMessage}
                                                placeholder="Envie uma mensagem para mim!"
                                                onChange={onChangeMessage}
                                                value={clientText}
                                            />
                                            <CharCounter>{clientTextCounter}/300</CharCounter>
                                        </>
                                    )}
                                />
                            </div>
                            <button type="submit" disabled={!formState.isValid}>
                                Enviar
                            </button>
                        </div>
                    </StyledForm>
                }

            </CenterBottomContentContainer>
        </ContactPage>

    )
}
export default Contact;
