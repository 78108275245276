import React, { Suspense } from 'react';
import { TopBanner, CenterBannerContent, ResponsiveContainer, LeftBoxTalkWithSpecialists, LeftBoxButtonArea, CallToActionText, CallToActionContainer } from './styles';
import { WhatsButton } from '../../components/WppBtn/WppBtn';
import { useNavigate } from 'react-router-dom';

const Main: React.FC = React.memo(() => {
    const navigate = useNavigate();

    return (
        <div>
            <TopBanner>
                <CenterBannerContent>
                    <ResponsiveContainer className="ResponsiveContainer">
                        <LeftBoxTalkWithSpecialists>
                            <div><h1>Serviços:</h1></div>
                            <LeftBoxButtonArea>
                                <a href="https://vinimanara.kpages.online/convivencia-canina">
                                    <button className='defaultButton'>
                                        <span>Curso: Convivência Canina</span>
                                    </button>
                                </a>
                                <a href="./cadastroEvento">
                                    <button className='defaultButton'>
                                        <span>Palestras e Eventos</span>
                                    </button>
                                </a>
                                {/* Lazy loading do botão de WhatsApp */}
                                <Suspense fallback={<div>Carregando...</div>}>
                                    <WhatsButton />
                                </Suspense>
                            </LeftBoxButtonArea>
                        </LeftBoxTalkWithSpecialists>
                    </ResponsiveContainer>
                </CenterBannerContent>
            </TopBanner>

            {/* Seção de convite para contato */}
            <CallToActionContainer>
                <CallToActionText>
                    <h2>Seu cão está apresentando algum problema comportamental?</h2>
                    <p>Não se preocupe! Entre em contato conosco pelo WhatsApp ou faça um cadastro rápido e nossa equipe entrará em contato para ajudar você e seu cão!</p>
                    <a href="#form" onClick={() => navigate('/cadastro')} >
                        <button className='defaultButton'> Faça o cadastro rápido</button>
                    </a>
                    <Suspense fallback={null}>
                        <WhatsButton floating />
                    </Suspense>
                </CallToActionText>
            </CallToActionContainer>
        </div >
    );
});

export default Main;
