import { EventRegister } from './styles';

const EventRegistration = () => {
    return (
        <EventRegister>

            <iframe
                src="https://docs.google.com/forms/d/e/1FAIpQLSfiEzd8QuVNPHnHFZW7upP_ygRNTy_rvq1-IyNOvihouOORDA/viewform?embedded=true"
                frameBorder="0"
                width="740"
                height="3500"
                style={{ overflowY: 'hidden' }} // Adicione este estilo
            >
                Carregando…
            </iframe>
        </EventRegister>
    )
}
export default EventRegistration;
